import React from "react";
import blog3 from "../../Assets/anand/bd3.png";
const BlogDetail3 = () => {
  return (
    <section className="bg-bg3 bg-repeat-y bg-contain bg-center pb-5">
      <h1 className="font-dm text-6xl font-normal leading-loose">Blogs</h1>
      <div className="mx-4 xl:mx-8">
        <div>
          <img src={blog3} loading="lazy" alt="img" />
        </div>
        <div className="bg-gradient-to-r from-[#FFD8AB] to-[#FFE5C7] pb-10">
          <div>
            <h1 className="pt-5 pb-4 font-bold text-[20px] xxs:text-[25px] xss:text-[25px] xs:text-[27px] md:text-[25px] xlg:text-[33px] lg:text-[35px] xl:text-[36px]">
              Unlocking positive energies: The significance and procedure <br />
              of Griha Pravesh puja or Housewarming Puja
            </h1>
          </div>
          <div>
            <div className="px-3 py-2">
              <h2 className="flex items-start">
                <b className="text-[19px] xxs:text-[22px] xss:text-[22px] xs:text-[26px] md:text-[25px] xlg:text-[32px] lg:text-[34px] xl:text-[35px]">
                  Introduction:
                </b>
              </h2>
              <p className="text-start text-[17px] xxs:text-[20px] xss:text-[20px] xs:text-[24px] md:text-[23px] xlg:text-[30px] lg:text-[32px] xl:text-[33px]">
              Griha Pravesh Puja, also known as housewarming puja or Hindu griha pravesh ceremony, a sacred
ritual performed before entering a new home, holds the key to dispelling ill effects and doshas,
ushering in positive energies, peace, and prosperity. Whether it&#39;s the purchase of a new home,
completion of renovations in an old dwelling, or the transition to a rental home, Griha Pravesh is a
revered ceremony designed to safeguard against natural calamities and bestow blessings upon the
inhabitants.
              </p>
            </div>
            <div className="px-3 py-2">
              <h2 className="flex items-start">
                <b className="text-[19px] xxs:text-[22px] xss:text-[22px] xs:text-[26px] md:text-[25px] xlg:text-[32px] lg:text-[34px] xl:text-[35px]">
                Hindu Griha Pravesh Puja Procedure:
                </b>
              </h2>
              <p className="text-start text-[17px] xxs:text-[20px] xss:text-[20px] xs:text-[24px] md:text-[23px] xlg:text-[30px] lg:text-[32px] xl:text-[33px]">
              The Griha Pravesh ceremony unfolds through various steps, including Dwar puja, boiling milk, Kalash,
Navgraha, Vastu puja, and an array of homas and poojas. This intricate procedure of housewarming
puja aims to purify the home and its surroundings, creating a divine ambiance and harmonious
vibrations.
              </p>
            </div>
            <div className="px-3 py-2">
              <h2 className="flex items-start">
                <b className="text-[19px] xxs:text-[22px] xss:text-[22px] xs:text-[26px] md:text-[25px] xlg:text-[32px] lg:text-[34px] xl:text-[35px]">
                  When to Perform Griha Pravesh Puja:
                </b>
              </h2>
              <p className="text-start text-[17px] xxs:text-[20px] xss:text-[20px] xs:text-[24px] md:text-[23px] xlg:text-[30px] lg:text-[32px] xl:text-[33px]">
              Timing is crucial in Griha Pravesh ceremony, and scheduling the puja on an auspicious Griha Pravesh
Muhurat or a favorable date based on the Rashi and Nakshatra of the house owners enhances its
efficacy. The alignment with cosmic energies during Griha pravesh ceremony ensures a positive and
prosperous beginning in the new abode.
              </p>
            </div>
            <div className="px-3 py-2">
              <h2 className="flex items-start">
                <b className="text-[19px] xxs:text-[22px] xss:text-[22px] xs:text-[26px] md:text-[25px] xlg:text-[32px] lg:text-[34px] xl:text-[35px]">
                  Benefits of Griha Pravesh Puja:
                </b>
              </h2>
              <p className="text-start text-[17px] xxs:text-[20px] xss:text-[20px] xs:text-[24px] md:text-[23px] xlg:text-[30px] lg:text-[32px] xl:text-[33px]">
                <b>1. Negative Energy Dispelling:</b> Shields the home from negative
                energy and the malevolent gaze, fostering an environment of
                positivity. <br />
                <br />
                <b>2. Housewarming Puja for Prosperity and Harmony:</b> Bestows the dwellers with prosperity,
                harmony, and good fortune, creating a conducive atmosphere for
                growth and well-being. <br />
                <br />
                <b>3. Purification and Spiritualization:</b> Infuses the surroundings
                with holy vibes, elevating the spiritual quotient of the home
                and its inhabitants. <br />
                <br />
                <b>4. Obstacle Removal:</b> Overcomes obstacles and hurdles that may
                arise in life, ensuring a smooth and prosperous journey for the
                dwellers. <br />
                <br />
                <b>5. Graha Shanti Puja and Vastu Shanti Puja:</b> Reverence for the
                Vastu Purush and the nine planets’ safeguards against untimely
                and unfortunate events, ensuring the well-being of both the home
                and its occupants.
              </p>
            </div>
            <div className="px-3 py-2">
              <h2 className="flex items-start">
                <b className="text-[19px] xxs:text-[22px] xss:text-[22px] xs:text-[26px] md:text-[25px] xlg:text-[32px] lg:text-[34px] xl:text-[35px]">
                  Conclusion:
                </b>
              </h2>
              <p className="text-start text-[17px] xxs:text-[20px] xss:text-[20px] xs:text-[24px] md:text-[23px] xlg:text-[30px] lg:text-[32px] xl:text-[33px]">
              Housewarming Puja transcends a mere ritual; it is a powerful Hindu Griha pravesh ceremony that
marks the beginning of a new chapter filled with positivity and divine blessings. Understanding its
significance and adhering to the prescribed procedures ensures not just the physical entry into a
new home but also a harmonious and prosperous life within its walls.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail3;
