import React from "react";
import HeroSection from "./HeroSection";
import Form from "../Home/Contact/Contact";
const Contact = () => {
  return (
    <main>
      <section>
        {/* <HeroSection /> */}
        <Form />
      </section>
    </main>
  );
};

export default Contact;
